<template>
  <div class="bg-white px-1 rounded-lg">
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Order Items') }}
    </h2>
    <span>
      <scan
        :asset-item-serial-numbers="assetItemSerialNumbers"
        scan-text="To start the put back process, press inspect or uncheck the items not to be inspected."
        @getValue="handleCheckBySerialNumber"
      />
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="putBackOrderItemsTableColumns"
        :module-name="MODULE_NAME"
        :fetched-data="orderItems"
        :is-searchable.camel="false"
        :row-active="{
          filedKey: 'inspected_inbound',
          classes: 'highlightRow'
        }"
      >
        <template #cell(state)="{ data: { item }}">
          <state-of-asset
            :data="item"
            :with-label="false"
            :styles="{ color: '#00B139', width: '18px', height: '18px' }"
          />
        </template>
        <template #cell(sku)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'product.sku', '—') }}
        </template>
        <template #cell(location)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'location.level_name', '—') }}
        </template>
        <template #cell(name)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'product.name', '—') }}
        </template>
        <template #cell(asset_id)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'asset_id', '—') }}
        </template>
        <template #cell(inspected)="{ data: { item }}">
          <div
            v-show="item.status.toString() !== assetStates.MISSING.code.toString()"
            class="text-center d-flex justify-content-between align-items-center"
          >
            <b-form-checkbox
              :checked="!!item.inspected_inbound"
              @change="handleUpdateItem(item.id, 'inspected_inbound')"
            />
            <feather-icon
              :class="`icon-wrapper ${(item.status.toString() === assetStates.SERVICE.code.toString() || item.put_backed_at || !item.inspected_inbound) && 'disabled'}`"
              icon="LWarningGreyIcon"
              size="25"
              @click="createServiceItem(item)"
            />
          </div>
        </template>
        <template #cell(put_back)="{ data: { item }}">
          <div
            v-show="item.status.toString() !== assetStates.MISSING.code.toString()"
            class="text-center"
          >
            <b-form-checkbox
              :checked="!!item.put_backed_at"
              :disabled="!item.inspected_inbound"
              @change="handleUpdateItem(item.id, 'put_backed_at')"
            />
          </div>
        </template>
      </l-table-list-collector>
      <change-asset-to-damaged
        ref="change-asset-to-damaged"
        fulfilment-order
        @orderDamagedReported="orderDamagedReported"
      />
    </span>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/receive/config'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import Scan from '@/views/components/Scan/Scan.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import map from 'lodash/map'
import { assetStates } from '@/enum/asset-statuses'
import * as _ from 'lodash'
import ChangeAssetToDamaged
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/ChangeAssetToDamaged.vue'

export default {
  name: 'OrderItems',
  components: {
    ChangeAssetToDamaged,
    StateOfAsset,
    LTableListCollector,
    BFormCheckbox,
    Scan,
  },
  props: {
    orderItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    assetItemSerialNumbers() {
      return map(this.orderItems, item => `${item?.inventory_item?.serial_number}_${item?.product?.sku}`)
    },
  },
  methods: {
    handleUpdateItem(id, fieldKey) {
      const payload = this.orderItems.find(item => item.id === id)
      if (!payload) return
      this.$emit('updateItem', { ...payload, [fieldKey]: payload[fieldKey] ? null : new Date().toString() })
    },
    handleCheckBySerialNumber(value) {
      const val = value.toString().trim()
      const checkedItems = _.find(this.orderItems, item => ((item?.inventory_item && item?.inventory_item?.serial_number === val) || (item.id.toString() === val) || item?.product?.sku === val))
      if (!checkedItems) return
      this.handleUpdateItem(checkedItems.id, 'put_backed_at')
    },
    createServiceItem(item) {
      if (item.status === assetStates.DAMAGED.code || item.status === assetStates.SERVICE.code || item.put_backed_at || !item.inspected_inbound) return

      this.$refs['change-asset-to-damaged'].show()
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/SET_DAMAGED_INVENTORY_ITEM_ID`, item.id)
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/SET_INVENTORY_LAST_ORDER`, null)
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/SET_INVENTORIES_LIST_REF`, this.$refs)
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/GET_PRODUCT`, item.product)
    },
    orderDamagedReported() {
      this.$emit('orderDamagedReported')
    },
  },
  setup() {
    const { putBackOrderItemsTableColumns, MODULE_NAME, INVENTORIES_MODULE_NAME } = config()

    return {
      getValueFromGivenObjectByKey, getProps, putBackOrderItemsTableColumns, MODULE_NAME, assetStates, INVENTORIES_MODULE_NAME,
    }
  },
}
</script>

<style lang="scss">

.disabled {
  cursor: not-allowed;
}

</style>
